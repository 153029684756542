import React from "react"
import Viewport from "../../components/common/viewport"
import ServiceContent from "../content/service/service-content"
import SEO from "../seo"

const Service = props => {
  const page = props.pageContext.service

  if (!page) return

  return (
    <Viewport>
      <ServiceContent pageContext={props.pageContext} />
    </Viewport>
  )
}

export default Service

export const Head = ({ location, pageContext }) => (
  <SEO
    pathname={location.pathname}
    title={pageContext.service.seoTitle}
    description={pageContext.service.seoDescription}
    keywords={pageContext.service.seoKeywords}
  />
)
