import React from "react"
import PropTypes from "prop-types"
import styled from "@emotion/styled"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import Section from "../../common/section"
import ListSlider from "./common/list-slider"
import Theme from "../../../theme/theme"
import Shape from "../../svg/shape"

const StyledSection = styled(Section)`
  position: relative;
  flex-direction: column;
  margin-bottom: 10rem;
`

const FavorContent = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  h2 {
    position: relative;
    font-size: 5rem;
    line-height: 6rem;
    max-width: 72rem;
    z-index: 2;

    @media (min-width: ${props => props.theme.devices.desktopMedium}) {
      font-size: 7rem;
      line-height: 9rem;
    }

    @media (min-width: ${props => props.theme.devices.desktopLarge}) {
      font-size: 10rem;
      line-height: 12rem;
    }
  }

  p {
    max-width: 45rem;
    font-size: 2.8rem;
    line-height: 3rem;
    margin-left: auto;
    margin-right: 10rem;
    margin-top: 2rem;

    @media (min-width: ${props => props.theme.devices.desktopMedium}) {
      font-size: 3.6rem;
      line-height: 4.8rem;
    }

    &:empty {
      display: none;
    }
  }
`

const SecondFavor = styled.div`
  h2 {
    font-size: 6rem;
    line-height: 7rem;
    margin: 6rem auto;
    min-width: 100%;
    text-align: center;

    @media (min-width: ${props => props.theme.devices.desktop}) {
      font-size: 10rem;
      line-height: 12rem;
      margin: 11.5rem auto 0;
    }
  }
`

const StyledListSlider = styled(ListSlider)`
  font-size: 3.6rem !important;
  line-height: 4.8rem !important;
  margin-top: 0 !important;

  @media (min-width: ${props => props.theme.devices.desktop}) {
    transform: rotate(-5deg);
  }

  > div > div {
    min-width: 60rem;
  }
`

const StyledShapeBg = styled(Shape)`
  position: absolute;
  top: -16rem;
  right: -80rem;
  max-width: initial;
  z-index: -5;
  transform: matrix(0.99, 0.12, -0.12, 0.99, 0, 0);
`

const FavorSection = ({ service }) => {
  if (!service.favor) return

  return (
    <StyledSection
      pageColor={Theme.colors.invertedText}
      textColor={Theme.colors.text}
      threshold={0.4}
    >
      <FavorContent>
        {documentToReactComponents(JSON.parse(service.favor.raw))}
      </FavorContent>
      <SecondFavor>
        <h2>{service.secondFavor}</h2>
      </SecondFavor>
      {service.favorSlider && (
        <StyledListSlider
          sliderItems={service.favorSlider}
          triggerClass="favor-trigger"
        />
      )}
      <StyledShapeBg type={6} isAnimated={false} />
    </StyledSection>
  )
}

FavorSection.propTypes = {
  service: PropTypes.object,
}

export default FavorSection
