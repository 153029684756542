import React from "react"
import PropTypes from "prop-types"
import styled from "@emotion/styled"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import Section from "../../common/section"
import ListSlider from "./common/list-slider"
import Theme from "../../../theme/theme"

const StyledSection = styled(Section)`
  position: relative;
  flex-direction: column;
`

const CooperationContent = styled.div`
  position: relative;

  h2 {
    position: relative;
    max-width: 100rem;
    z-index: 2;
    font-size: 6rem;
    line-height: 7rem;

    @media (min-width: ${props => props.theme.devices.desktop}) {
      font-size: 10rem;
      line-height: 12rem;
      margin: 11.5rem auto 0;
    }
  }
`

const StyledListSlider = styled(ListSlider)`
  @media (min-width: ${props => props.theme.devices.desktop}) {
    transform: rotate(5deg);
  }

  > div > div {
    min-width: 40rem;
  }
`

const CooperationSection = ({ service }) => {
  if (!service.cooperation) return

  return (
    <StyledSection
      pageColor={Theme.colors.invertedText}
      textColor={Theme.colors.text}
      threshold={0.4}
    >
      <CooperationContent>
        {documentToReactComponents(JSON.parse(service.cooperation.raw))}
      </CooperationContent>
      {service.cooperationSlider && (
        <StyledListSlider
          sliderItems={service.cooperationSlider}
          triggerClass="cooperation-trigger"
        />
      )}
    </StyledSection>
  )
}

CooperationSection.propTypes = {
  service: PropTypes.object,
}

export default CooperationSection
