import React from "react"
import PropTypes from "prop-types"
import styled from "@emotion/styled"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import Section from "../../common/section"
import GatsbyImage from "../../common/gatsby-image"
import Shape from "../../svg/shape"
import Theme from "../../../theme/theme"

const StyledSection = styled(Section)`
  position: relative;
  flex-direction: column;
  background-color: ${props => props.theme.colors.gray};
  margin: 0;
`

const IntroContent = styled.div`
  position: relative;
  padding-top: 5rem;
  z-index: 1;

  @media (min-width: ${props => props.theme.devices.desktop}) {
    padding-top: 11rem;
    padding-bottom: 5rem;
  }
`

const Content = styled.div`
  position: relative;
  z-index: 1;

  .gatsby-image-wrapper {
    max-width: 80rem;
  }

  h1 {
    max-width: 80rem;
    font-size: 3.8rem;
    line-height: 4rem;

    @media (min-width: ${props => props.theme.devices.desktopMedium}) {
      font-size: 5.8rem;
      line-height: 6rem;
    }

    @media (min-width: ${props => props.theme.devices.desktopLarge}) {
      font-size: 6.8rem;
      line-height: 8rem;
    }
  }

  p {
    max-width: 80rem;
    font-size: 3.8rem;
    line-height: 4rem;

    @media (min-width: ${props => props.theme.devices.desktopMedium}) {
      font-size: 5.8rem;
      line-height: 6rem;
    }

    @media (min-width: ${props => props.theme.devices.desktopLarge}) {
      font-size: 6.8rem;
      line-height: 8rem;
    }

    &:first-of-type {
      position: relative;
      font-size: 6rem;
      line-height: 7rem;
      font-family: "darker_grotesquebold", sans-serif;
      max-width: 100%;
      margin-bottom: 4rem;

      @media (min-width: ${props => props.theme.devices.desktop}) {
        font-size: 10rem;
        line-height: 10rem;
        margin-bottom: 7rem;
      }

      @media (min-width: ${props => props.theme.devices.desktopLarge}) {
        font-size: 17rem;
        line-height: 17rem;
        margin-bottom: 10rem;
      }

      @supports (-webkit-text-stroke: 1px black) {
        color: transparent;
        -webkit-text-stroke-width: 0.1rem;
        -webkit-text-stroke-color: ${props => props.theme.colors.invertedText};
      }
    }
  }
`

const InfoText = styled.p`
  position: relative;
  max-width: 50rem;
  font-size: 1.8rem;
  line-height: 3.6rem;
  letter-spacing: 0.4rem;
  text-transform: uppercase;
  margin-left: auto;
  padding-top: 2rem;
  padding-bottom: 5rem;
  z-index: 1;

  @media (min-width: ${props => props.theme.devices.desktop}) {
    text-align: right;
    padding-top: 0;
    padding-bottom: 20rem;
  }
`

const BgImage = styled(GatsbyImage)`
  @media (min-width: ${props => props.theme.devices.desktop}) {
    position: absolute;
    top: 0;
    right: -3rem;
    z-index: 0;
  }
`

const StyledShape = styled(Shape)`
  position: absolute;
  top: 0;
  left: -10rem;
  z-index: 0;
  width: 81rem;
  height: 81rem;
`

const StyledShape2 = styled(Shape)`
  position: absolute;
  top: -7rem;
  right: 0;
  width: 32rem;
  transform: rotate(90deg);
  z-index: 0;
`

const StyledShape3 = styled(Shape)`
  position: absolute;
  top: 28rem;
  right: 7rem;
  z-index: 0;
  width: 20rem;
  transform: rotate(-90deg);
`
const IntroSection = ({ service }) => {
  return (
    <StyledSection
      pageColor={Theme.colors.invertedText}
      textColor={Theme.colors.invertedText}
      threshold={0.4}
    >
      <IntroContent>
        <Content>
          {documentToReactComponents(JSON.parse(service.introText.raw))}
        </Content>

        <InfoText>{service.info}</InfoText>
        <BgImage image={service.introImage.gatsbyImageData} />
        <StyledShape type={8} isThirdColor={true} />
        <StyledShape2 type={2} isMainColor={true} />
        <StyledShape3 type={1} isSecondColor={true} />
      </IntroContent>
    </StyledSection>
  )
}

IntroSection.propTypes = {
  service: PropTypes.object,
}

export default IntroSection
