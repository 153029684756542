import React from "react"
import IntroSection from "./intro-section"
import ContentSection from "./content-section"
import FavorSection from "./favor-section"
import CooperationSection from "./cooperation-section"
import FunctionsSection from "./functions-section"
import ContactSection from "../../common/contact/contact-section"
import Theme from "../../../theme/theme"

const ServiceContent = ({ pageContext }) => {
  return (
    <>
      <IntroSection service={pageContext.service} />
      <ContentSection service={pageContext.service} />
      <FavorSection service={pageContext.service} />
      <FunctionsSection service={pageContext.service} />
      <CooperationSection service={pageContext.service} />
      <ContactSection
        content={pageContext.service.contact}
        sectionColor={Theme.colors.gray2}
        contactPageSlug={pageContext.contactPageSlug}
      />
    </>
  )
}

export default ServiceContent
