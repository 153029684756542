import React from "react"
import PropTypes from "prop-types"
import styled from "@emotion/styled"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import Section from "../../common/section"
import Shape from "../../svg/shape"
import Theme from "../../../theme/theme"
import ContentSlider from "../../common/content-slider"

const StyledSection = styled(Section)`
  position: relative;
  flex-direction: column;
`

const FunctionsContent = styled.div`
  position: relative;
  z-index: 2;

  h2 {
    position: relative;
    font-size: 5rem;
    line-height: 6rem;
    max-width: 72rem;
    z-index: 2;

    @media (min-width: ${props => props.theme.devices.desktopMedium}) {
      font-size: 7rem;
      line-height: 9rem;
    }

    @media (min-width: ${props => props.theme.devices.desktopLarge}) {
      font-size: 10rem;
      line-height: 12rem;
    }
  }
`

const StyledContentSlider = styled(ContentSlider)`
  position: relative;
  margin-top: 5rem;
  z-index: 10;
`

const StyledShape1 = styled(Shape)`
  position: absolute;
  top: 12rem;
  right: -5rem;
  z-index: 0;
  width: 100rem;
  height: 100rem;
`

const StyledShape2 = styled(Shape)`
  position: absolute;
  top: 6rem;
  right: 18rem;
  z-index: 0;
  width: 18rem;
  height: 18rem;
`

const FunctionsSection = ({ service }) => {
  if (!service.functionsSlider || service.functionsSlider?.length === 0) return

  return (
    <StyledSection
      pageColor={Theme.colors.invertedText}
      textColor={Theme.colors.text}
      threshold={0.4}
    >
      <FunctionsContent>
        {documentToReactComponents(JSON.parse(service.functions.raw))}
      </FunctionsContent>
      <StyledShape1 type={8} isSecondColor={true} />
      <StyledShape2 type={8} isThirdColor={true} />
      <StyledContentSlider items={service.functionsSlider} isFunctions={true} />
    </StyledSection>
  )
}

FunctionsSection.propTypes = {
  service: PropTypes.object,
}

export default FunctionsSection
