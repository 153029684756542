import React from "react"
import PropTypes from "prop-types"
import styled from "@emotion/styled"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import { BLOCKS } from "@contentful/rich-text-types"
import Section from "../../common/section"
import Theme from "../../../theme/theme"
import Arrow from "../../svg/arrow"
import Shape from "../../svg/shape"

const StyledSection = styled(Section)`
  position: relative;
  flex-direction: column;
  background-color: ${props => props.theme.colors.gray};
`

const FirstContent = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  border-bottom: 0.6rem solid ${props => props.theme.colors.invertedText};
  padding-bottom: 5rem;
  z-index: 1;

  @media (min-width: ${props => props.theme.devices.desktop}) {
    flex-direction: row;
    padding-top: 11rem;
    padding-bottom: 15rem;
  }

  h2 {
    font-family: "darker_grotesquelight", sans-serif;
    font-size: 5rem;
    line-height: 6rem;

    @media (min-width: ${props => props.theme.devices.desktop}) {
      min-width: 50rem;
      margin-right: 16rem;
    }

    @media (min-width: ${props => props.theme.devices.desktopMedium}) {
      font-size: 7rem;
      line-height: 9rem;
    }

    @media (min-width: ${props => props.theme.devices.desktopLarge}) {
      font-size: 10rem;
      line-height: 12rem;
    }
  }

  p {
    max-width: 96rem;
    font-size: 2.8rem;
    line-height: 3rem;
    margin-top: 2rem;

    @media (min-width: ${props => props.theme.devices.desktopMedium}) {
      margin-left: auto;
      font-size: 4.8rem;
      line-height: 5rem;
      margin-top: 0;
    }

    @media (min-width: ${props => props.theme.devices.desktopLarge}) {
      font-size: 6.8rem;
      line-height: 8rem;
    }
  }
`

const SecondContent = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  padding-top: 5rem;
  padding-bottom: 5rem;
  z-index: 1;

  @media (min-width: ${props => props.theme.devices.desktop}) {
    flex-direction: row;
    padding-top: 11rem;
    padding-bottom: 18rem;
  }

  h2 {
    font-family: "darker_grotesquelight", sans-serif;
    font-size: 4rem;
    line-height: 6rem;

    @media (min-width: ${props => props.theme.devices.desktop}) {
      min-width: 50rem;
      margin-right: 16rem;
    }

    @media (min-width: ${props => props.theme.devices.desktopMedium}) {
      font-size: 7rem;
      line-height: 9rem;
    }

    @media (min-width: ${props => props.theme.devices.desktopLarge}) {
      font-size: 10rem;
      line-height: 12rem;
    }
  }

  ul {
    max-width: 96rem;
    width: 100%;
    list-style-type: none;
    margin-left: auto;
    font-size: 3.2rem;
    line-height: 3.8rem;

    @media (min-width: ${props => props.theme.devices.desktopMedium}) {
      font-size: 4.8rem;
      line-height: 5rem;
    }

    @media (min-width: ${props => props.theme.devices.desktopLarge}) {
      font-size: 6.8rem;
      line-height: 8rem;
    }

    li {
      position: relative;
      padding: 0 0 5.5rem 4.5rem;
      margin-top: 4.5rem;
      border-bottom: 0.3rem solid ${props => props.theme.colors.invertedText};

      @media (min-width: ${props => props.theme.devices.desktop}) {
        margin-top: 3.5rem;
      }

      &:first-of-type {
        margin-top: 2.5rem;

        @media (min-width: ${props => props.theme.devices.desktop}) {
          margin-top: 1.2rem;
        }
      }

      &:last-child {
        border-bottom: none;
        padding-bottom: 0;
      }

      svg {
        position: absolute;
        top: 1rem;
        left: 0;
        width: 2.6rem;
        transform: rotate(90deg);

        path {
          fill: ${props => props.theme.colors.main};
        }

        @media (min-width: ${props => props.theme.devices.desktopMedium}) {
          top: 1.5rem;
        }

        @media (min-width: ${props => props.theme.devices.desktopLarge}) {
          top: 3rem;
        }
      }
    }
  }
`

const StyledShape1 = styled(Shape)`
  position: absolute;
  top: -4rem;
  left: 15%;
  z-index: 0;
  width: 10rem;
  height: 10rem;
`

const StyledShape2 = styled(Shape)`
  position: absolute;
  top: -13rem;
  left: 32%;
  width: 17rem;
  transform: rotate(90deg);
  z-index: 0;
`

const StyledShape3 = styled(Shape)`
  position: absolute;
  top: 2rem;
  right: -3rem;
  z-index: 0;
  width: 26rem;
  height: 26rem;
`

const options = {
  renderNode: {
    [BLOCKS.UL_LIST]: (node, children) => {
      if (!children) return

      return (
        <ul>
          {children.map((child, index) => (
            <li key={index}>
              <Arrow />
              {child.props.children}
            </li>
          ))}
        </ul>
      )
    },
  },
}

const ContentSection = ({ service }) => {
  return (
    <StyledSection
      pageColor={Theme.colors.invertedText}
      textColor={Theme.colors.invertedText}
      threshold={0.2}
    >
      <FirstContent>
        {documentToReactComponents(JSON.parse(service.content.raw))}
      </FirstContent>
      <SecondContent>
        {documentToReactComponents(
          JSON.parse(service.secondContent.raw),
          options,
        )}
      </SecondContent>
      <StyledShape1 type={8} isMainColor={true} />
      <StyledShape2 type={1} isThirdColor={true} />
      <StyledShape3 type={8} isMainColor={true} />
    </StyledSection>
  )
}

ContentSection.propTypes = {
  service: PropTypes.object,
}

export default ContentSection
