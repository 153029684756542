import React, { useEffect, useRef } from "react"
import PropTypes from "prop-types"
import styled from "@emotion/styled"
import { gsap } from "gsap"
import { useMediaQuery } from "react-responsive"
import SingleItem from "./single-item"
import devices from "../../../../theme/devices"

const ListSliderElement = styled.div`
  position: relative;
  font-size: 2.8rem !important;
  line-height: 3.8rem !important;
  margin-top: 10rem;

  @media (min-width: ${props => props.theme.devices.desktop}) {
    line-height: 4.8rem !important;
    margin-bottom: 7rem;
  }

  ${props =>
    props.$isMobile &&
    `
    overflow-x: scroll;
    padding-bottom: 5rem;
  `}

  > div {
    display: flex;
    justify-content: flex-start;
    flex-wrap: nowrap;
  }
`

const EmptyItem = styled.div`
  width: 5rem;
  min-width: 10rem !important;
`

const ListSlider = ({ className, sliderItems, triggerClass }) => {
  const ref = useRef()
  const parent = useRef()
  const isMobile = useMediaQuery({
    query: `(max-device-width: ${devices.desktop})`,
  })

  useEffect(() => {
    if (!ref?.current || !parent || isMobile) return

    const gsapAnimation = gsap.to(parent.current, {
      x: function () {
        return (
          -(ref.current.scrollWidth - document.documentElement.clientWidth) +
          "px"
        )
      },
      ease: "none",
      scrollTrigger: {
        trigger: `.${triggerClass}`,
        start: "center center",
        end: "+=" + ref.current.clientWidth,
        scrub: true,
        pin: true,
      },
    })

    return () => {
      gsapAnimation.kill()
    }
  }, [ref, parent, isMobile, triggerClass])

  if (!sliderItems) return

  return (
    <div ref={ref}>
      <ListSliderElement
        className={`${triggerClass} ${className}`}
        $isMobile={isMobile}
      >
        <div ref={parent}>
          {sliderItems.map((item, index) => (
            <SingleItem key={item.contentful_id} item={item} index={index} />
          ))}
          <EmptyItem />
        </div>
      </ListSliderElement>
    </div>
  )
}

ListSlider.propTypes = {
  className: PropTypes.string,
  sliderItems: PropTypes.array,
  triggerClass: PropTypes.string,
}

export default ListSlider
