import React from "react"
import styled from "@emotion/styled"
import PropTypes from "prop-types"
import GatsbyImage from "../../../common/gatsby-image"

const SingleItemElement = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  font-size: inherit;
  line-height: 3.5rem !important;
  max-width: 70rem;
  color: ${props =>
    props.backgroundColor === "#ACE0EC"
      ? props.theme.colors.text
      : props.theme.colors.invertedText};
  background: ${props =>
    props.backgroundColor ? props.backgroundColor : props.theme.colors.main};
  padding: 2rem 5rem 3rem 18rem;
  margin: 0 2rem;
  border-radius: 12rem;

  @media (min-width: ${props => props.theme.devices.desktop}) {
    padding: 3rem 5rem 4rem 18rem;
  }
`

const ItemIndex = styled.span`
  position: absolute;
  top: 50%;
  left: 4.5rem;
  font-size: 6.8rem;
  line-height: 4rem;
  font-family: "darker_grotesquebold", sans-serif;
  transform: translate3d(0, -50%, 0);
  margin-top: -0.5rem;
`

const StyledGatsbyImage = styled(GatsbyImage)`
  position: absolute;
  top: 50%;
  left: 5rem;
  max-width: 10rem;
  font-size: 6.8rem;
  line-height: 4rem;
  font-family: "darker_grotesquebold", sans-serif;
  transform: translate3d(0, -50%, 0);
`

const SingleItem = ({ item, index }) => {
  if (!item) return

  return (
    <SingleItemElement backgroundColor={item.backgroundColor}>
      {item.image ? (
        <StyledGatsbyImage image={item.image.gatsbyImageData} />
      ) : (
        <ItemIndex>
          {index < 10 ? "0" : ""}
          {index + 1}.
        </ItemIndex>
      )}
      {item.title}
    </SingleItemElement>
  )
}

SingleItem.propTypes = {
  item: PropTypes.object,
}

export default SingleItem
